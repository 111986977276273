<script lang="ts">
</script>
<script setup lang="ts">
import { useSeoMeta } from '#imports'

const useJavaScript: string = `このWebサイトの全ての機能を利用するためにはJavaScriptを有効にする必要があります。<br>
<a href="https://www.enable-javascript.com/ja/">あなたのWebブラウザーでJavaScriptを有効にする方法</a>を参照してください。<br>`

useSeoMeta({
  description: '猫についてのナレッジを共有するサイト',
  ogType: 'website',
  ogTitle: 'トップ',
  ogDescription: '猫についてのナレッジを共有するサイト',
})
</script>
<template>
  <v-container>
    <v-sheet>
      <h1 class="mb-4">
        <span id="title">nekonare(仮)</span>
      </h1>
      <p class="text-caption mb-8">
        猫に関するナレッジを共有するサイトです。<br>
        このサイトを見た人が新しい猫の知見を得られることを目指しています。<br>
        <br>
        他のサイトで取り扱っていない情報を収集・構造化しています。<br>
      </p>
    </v-sheet>
    <noscript v-html="useJavaScript" />
  </v-container>
</template>